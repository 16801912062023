import React, { useState, useEffect } from 'react';
import PhotoInterpolation, { PhotoMapping, photoMappingFromMap } from "./PhotoInterpolation";
import { Tabs, Tab, Box, Button, Alert } from '@mui/material'; // Import Alert from Material-UI

type Photo = [Uint8Array, number, number];

interface MappingCalculatorProps {
    imgdata1: Photo | null;
    imgdata2: Photo | null;
}

const MappingCalculator: React.FC<MappingCalculatorProps> = ({ imgdata1, imgdata2 }) => {
    const [result, setResult] = useState(null);
    const [worker, setWorker] = useState<Worker | null>(null);
    const [state, setState] = useState<string | null>(null);
    const [photoMappings, setPhotoMappings] = useState<PhotoMapping[]>([]);
    const [activeTab, setActiveTab] = useState<number>(0); // State to track the active tab
    const [errorMessage, setErrorMessage] = useState<string | null>(null); // State for error messages

    useEffect(() => {
        // Create a new web worker
        const myWorker: Worker = new Worker('worker.js', { type: 'module' });

        // Set up event listener for messages from the worker
        myWorker.onmessage = function (event) {
            setResult(event.data);
            if (event.data.hasOwnProperty("state")) {
                setState(event.data.state);

                if (event.data.state === "done") {
                    // Handle completion if needed
                }
            }
            if (event.data.hasOwnProperty("pm")) {
                let pm = event.data.pm;
                let pm2 = photoMappingFromMap(pm);

                setPhotoMappings(prevMappings => {
                    const updatedMappings = [...prevMappings, pm2];
                    setActiveTab(updatedMappings.length - 1); // Set the new tab as the active tab
                    return updatedMappings;
                });
                document.getElementById("tabView")?.scrollIntoView();
            }
        };

        myWorker.onerror = function (error) {
            console.error('Worker error:', error);
        };

        // Save the worker instance to state
        setWorker(myWorker);

        // Clean up the worker when the component unmounts
        return () => {
            myWorker.terminate();
        };
    }, []); // Run this effect only once when the component mounts

    const handleClick = () => {
        // Reset any previous error message
        setErrorMessage(null);

        // Check if both images are selected
        if (imgdata1 == null || imgdata2 == null) {
            setErrorMessage("Please select two images before calculating.");
            return;
        }

        const [data1, width1, height1] = imgdata1;
        const [data2, width2, height2] = imgdata2;


        console.log("w/h "+width1+ " " + height1+" "+width2 +" " + height2);
        // Check if images have the same dimensions
        if (width1 !== width2 || height1 !== height2) {
            setErrorMessage("The two photos must have the same width and height");
            return;
        }

        // Proceed with calculation if no errors
        if (worker) {
            setPhotoMappings([]); // Clear previous mappings
            worker.postMessage({
                name: "create",
                imgdata1: data1,
                imgdata2: data2,
                width: width1,
                height: height1,
            });
        }
    };

    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setActiveTab(newValue);
    };

    return (
        <div id="tabView">
            {/* Display error message if exists */}
            {errorMessage && (
                <Alert severity="error" onClose={() => setErrorMessage(null)} sx={{ mb: 2 }}>
                    {errorMessage}
                </Alert>
            )}
            <Box display="flex" justifyContent="center" mb={2}>
                <Button variant="contained" color="success" onClick={handleClick}
                        sx={{
                            padding: '12px 24px',
                            fontSize: '1.5rem',
                        }}
                >
                    Start calculating photo mapping
                </Button>
            </Box>

            {/* Tabs to display each PhotoInterpolation */}
            {photoMappings.length > 0 && (
                <>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider', marginTop: 2 }}>
                        <Tabs value={activeTab} onChange={handleTabChange} aria-label="photo interpolation tabs">
                            {photoMappings.map((_, index) => (
                                <Tab key={index} label={`Iteration ${index + 1}`} />
                            ))}
                        </Tabs>
                    </Box>

                    {/* Display the currently selected PhotoInterpolation */}
                    {photoMappings.map((photoMapping, index) => (
                        <div key={index} style={{ display: activeTab === index ? 'block' : 'none' }}>
                            <PhotoInterpolation photoMapping={photoMapping} />
                        </div>
                    ))}
                </>
            )}
        </div>
    );
};

export default MappingCalculator;
