// ExamplePhotoList.tsx
import React from 'react';
import { Box, Typography, Grid, Card, CardActionArea, CardMedia } from '@mui/material';

interface ExamplePhoto {
    photoA: string;
    photoB: string;
}

interface ExamplePhotoListProps {
    examplePhotos: ExamplePhoto[];
    onExamplePhotoClick: (photoA: string, photoB: string) => void;
}

const ExamplePhotoList: React.FC<ExamplePhotoListProps> = ({ examplePhotos, onExamplePhotoClick }) => {
    return (
        <Box sx={{ width: '20%', p: 2, maxHeight: '500px', overflowY: 'auto' }}>
            <Typography variant="h6" gutterBottom>
                Example photos (click too choose)
            </Typography>
            <Grid container spacing={2}>
                {examplePhotos.map((photos, index) => (
                    <Grid item xs={12} key={index}>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <Card
                                    onClick={() => onExamplePhotoClick(photos.photoA, photos.photoB)}
                                    sx={{ cursor: 'pointer' }}
                                >
                                    <CardActionArea>
                                        <CardMedia
                                            component="img"
                                            height="100"
                                            image={photos.photoA}
                                            alt={`Exempel A ${index + 1}`}
                                        />
                                    </CardActionArea>
                                </Card>
                            </Grid>
                            <Grid item xs={6}>
                                <Card
                                    onClick={() => onExamplePhotoClick(photos.photoA, photos.photoB)}
                                    sx={{ cursor: 'pointer' }}
                                >
                                    <CardActionArea>
                                        <CardMedia
                                            component="img"
                                            height="100"
                                            image={photos.photoB}
                                            alt={`Exempel B ${index + 1}`}
                                        />
                                    </CardActionArea>
                                </Card>
                            </Grid>
                        </Grid>
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
};

export default ExamplePhotoList;
