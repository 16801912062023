// PhotoLoader.tsx
import React, { useState, useRef } from 'react';
import { Box } from '@mui/material';
import PhotoArea from './PhotoArea';
import ExamplePhotoList from './ExamplePhotoList';

interface PhotoLoaderProps {
    onImageData1Change: (data: [Uint8Array, number, number]) => void;
    onImageData2Change: (data: [Uint8Array, number, number]) => void;
}

const PhotoLoader: React.FC<PhotoLoaderProps> = ({ onImageData1Change, onImageData2Change }) => {
    const [photoA, setPhotoA] = useState<string | null>(null);
    const [photoB, setPhotoB] = useState<string | null>(null);

    // Skapa ref för varje PhotoArea
    const photoAreaARef = useRef<HTMLImageElement | null>(null);
    const photoAreaBRef = useRef<HTMLImageElement | null>(null);

    // Exempelbilder
    const examplePhotos = [
        { photoA: '/photos/e1.jpg', photoB: '/photos/e2.jpg' },
        { photoA: '/photos/k1.jpg', photoB: '/photos/k2.jpg' },
        { photoA: '/photos/c1.jpg', photoB: '/photos/c2.jpg' },
        { photoA: '/photos/a1.jpg', photoB: '/photos/a2.jpg' },
        { photoA: '/photos/b1.jpg', photoB: '/photos/b2.jpg' },
    ];

    const handleExamplePhotoClick = (photoAUrl: string, photoBUrl: string) => {
        setPhotoA(photoAUrl);
        setPhotoB(photoBUrl);
    };

    // Funktion för att konvertera bild till Uint8Array
    function imageToUint8Array(img: HTMLImageElement): [Uint8Array, number, number] {
        const canvas: HTMLCanvasElement = document.createElement('canvas');
        canvas.width = img.naturalWidth;
        canvas.height = img.naturalHeight;
        const ctx = canvas.getContext('2d') as CanvasRenderingContext2D;
        ctx.drawImage(img, 0, 0);
        const imageData: ImageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
        const pixelData: Uint8Array = new Uint8Array(imageData.data.buffer);
        return [pixelData, img.naturalWidth, img.naturalHeight];
    }

    // Hanterare för när bild A har laddats
    const handleImageALoad = () => {
        if (photoAreaARef.current) {
            const [data, width, height] = imageToUint8Array(photoAreaARef.current);
            onImageData1Change([data, width, height]);
        }
    };

    // Hanterare för när bild B har laddats
    const handleImageBLoad = () => {
        if (photoAreaBRef.current) {
            const [data, width, height] = imageToUint8Array(photoAreaBRef.current);
            onImageData2Change([data, width, height]);
        }
    };

    return (
        <Box display="flex" flexDirection="row" p={3}>
            {/* Vänster panel - Exempelbilder */}
            <ExamplePhotoList examplePhotos={examplePhotos} onExamplePhotoClick={handleExamplePhotoClick} />

            {/* Bildrutor */}
            <Box display="flex" flexDirection="column" width="80%">
                <Box display="flex" flexDirection="row">
                    <PhotoArea
                        ref={photoAreaARef}
                        selectedPhoto={photoA}
                        title="Photo Area A"
                        onLoadPhoto={setPhotoA}
                        onImageLoad={handleImageALoad} // Lägg till onImageLoad-propen
                    />
                    <PhotoArea
                        ref={photoAreaBRef}
                        selectedPhoto={photoB}
                        title="Photo Area B"
                        onLoadPhoto={setPhotoB}
                        onImageLoad={handleImageBLoad} // Lägg till onImageLoad-propen
                    />
                </Box>
            </Box>
        </Box>
    );
};

export default PhotoLoader;
