import React, {useState} from 'react';
import {Typography} from '@mui/material';
import MappingCalculator from "./mappingCalculator";
import {createTheme, ThemeProvider} from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import PhotoLoader from "./PhotoLoader";


const darkTheme = createTheme({
    palette: {
        mode: 'dark', // Detta sätter dark mode för Material-UI komponenter
    },
});


const App: React.FC = () => {
  const [imgdata1, setImageData1] = useState<[Uint8Array, number, number] | null>(null);
  const [imgdata2, setImageData2] = useState<[Uint8Array, number, number] | null>(null);



  return (
      <ThemeProvider theme={darkTheme}>
          <CssBaseline />
          <Typography variant="h4" align="center" gutterBottom>
              Create a Photo Mapping from two photos
          </Typography>
          <PhotoLoader
              onImageData1Change={setImageData1}
              onImageData2Change={setImageData2}
          />
          <MappingCalculator imgdata1={imgdata1} imgdata2={imgdata2}></MappingCalculator>
      </ThemeProvider>
  );
};

export default App;
