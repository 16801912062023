import React, { useRef, useImperativeHandle } from 'react';
import { Box, Button, Typography } from '@mui/material';

interface PhotoAreaProps {
    selectedPhoto: string | null;
    title: string;
    onLoadPhoto: (photo: string) => void;
    onImageLoad?: () => void;
}

const PhotoArea = React.forwardRef<HTMLImageElement | null, PhotoAreaProps>(
    ({ selectedPhoto, title, onLoadPhoto, onImageLoad }, ref) => {
        const fileInputRef = useRef<HTMLInputElement | null>(null);
        const imgRef = useRef<HTMLImageElement | null>(null);

        // Exponera imgRef till föräldern via ref
        useImperativeHandle(ref, () => imgRef.current as HTMLImageElement);

        const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
            if (event.target.files && event.target.files[0]) {
                const file = event.target.files[0];
                const reader = new FileReader();
                reader.onload = () => {
                    onLoadPhoto(reader.result as string);
                };
                reader.readAsDataURL(file);
            }
        };

        const handleLoadClick = () => {
            fileInputRef.current?.click();
        };

        return (
            <Box
                sx={{
                    width: '50%',
                    height: '100%',
                    border: '1px solid black',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    p: 2,
                }}
            >
                <Typography variant="h6">{title}</Typography>
                <Box
                    sx={{
                        width: '100%',
                        height: '400px',
                        border: '1px solid black',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        mt: 1,
                    }}
                >
                    {selectedPhoto ? (
                        <img
                            src={selectedPhoto}
                            alt={title}
                            id={title.replace(/\s/g, '-') + '-img'}
                            ref={imgRef} // Anslut ref till img-elementet
                            style={{ maxWidth: '100%', maxHeight: '100%' }}
                            onLoad={onImageLoad}
                        />
                    ) : (
                        <Typography variant="body1" color="textSecondary">
                            Select photo
                        </Typography>
                    )}
                </Box>
                <input
                    type="file"
                    accept="image/*"
                    ref={fileInputRef}
                    style={{ display: 'none' }}
                    onChange={handleFileChange}
                />
                <Button variant="contained" color="primary" onClick={handleLoadClick} sx={{ mt: 2 }}>
                    Load image from disk
                </Button>
            </Box>
        );
    }
);

export default PhotoArea;
